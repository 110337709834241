import React from "react"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts/index"
import IceInfoParagraph from "../components/ice-info-paragraph/ice-info-paragraph.js";
import { about, team } from "../utils/information.js";


const About = () => {


  return (
    <Layout
      SEOTitle={"About Us - ICE"}
      SEODescription={"Our goal is to find every customer their cheapest insurance price in the easiest possible way! Established in March 2011, ICE manages a number of price comparison websites for general insurance in the automotive sector. The team at ICE are focused on the customer outcome, aiming to provide a simple, quick and cost-effective solution."}
      footerDisclaimer={""}>

      <IceInfoParagraph props={about} main={true} />
      <IceInfoParagraph props={team} />

    </Layout>
  )

}

export default About